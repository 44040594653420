* {
  box-sizing: border-box;
  font-family:"CMU Classical Serif";
}

body {
  margin: 0;
  padding: 0;
}

.app {
  background-color: rgba(0, 0, 0, 0.892);
}


.Main {
  height: 100%;
}

